<template>
  <div>
    <content-section v-if="vehicle">
      <div class="my-5">
        <oto-typography
          :text="
            $t(
              'components.vehicleDetailsManagement.diveIntoTab.flags.operational.title'
            )
          "
          variant="one"
        />
        <div class="grid grid-cols-1 mt-5 gap-5 md:grid-cols-4">
          <VehicleFlagBox
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.onReserved'
              )
            "
            icon="on-reservation"
            :status="vehicle.status_flags.is_reserved"
          />
          <VehicleFlagBox
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.onRide'
              )
            "
            icon="on-ride"
            :status="vehicle.status_flags.is_on_ride"
          />
          <toggle-box
            v-model="vehicle.general_flags.hibernate_mode"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatusForSleepMode"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'hibernate_mode', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="hibernate_mode" />
                <div class="ml-3 title">
                  <div>
                    {{
                      $t(
                        'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.sleepMode'
                      )
                    }}
                  </div>
                  <!-- <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'REBALANCING'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div> -->
                </div>
              </div>
            </template>
          </toggle-box>
          <toggle-box
            v-model="vehicle.operational_flags.rebalance"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="makeToggleSense(true)"
            @change="onOff('O', 'rebalance', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="rebalance" />
                <div class="ml-3 title">
                  <div>
                    {{
                      $t(
                        'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.rebalance'
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'REBALANCING'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.operational_flags.charging_pick"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="makeToggleSense(true)"
            @change="onOff('O', 'charging_pick', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="charging-pick" />
                <div class="ml-4 title">
                  <div>
                    {{
                      $t(
                        'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.chargingPick'
                      )
                    }}
                  </div>
                  <div
                    v-if="vehicle.task && vehicle.task.task_type === 'CHARGING'"
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.operational_flags.maintenance"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="makeToggleSense(true)"
            @change="onOff('O', 'maintenance', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="maintenance" />
                <div class="ml-3 title">
                  <div>
                    {{
                      $t(
                        'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.maintenance'
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'MAINTENANCE'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>
          <toggle-box
            v-model="vehicle.operational_flags.swap_battery"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="makeToggleSense(true)"
            @change="onOff('O', 'swap_battery', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="swap_battery" />
                <div class="ml-4 title">
                  <div>
                    {{
                      $t(
                        'components.vehicleDetailsManagement.diveIntoTab.flags.operational.flags.swapBattery'
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'SWAP_BATTERY'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>
        </div>
      </div>

      <div class="my-5">
        <oto-typography
          :text="
            $t(
              'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.title'
            )
          "
          variant="one"
        />
        <div class="grid grid-cols-1 mt-5 gap-5 md:grid-cols-4">
          <VehicleFlagBox
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.parking'
              )
            "
            icon="on-parking"
            :status="
              vehicle.status_flags.is_parking || vehicle.status_flags.is_idle
            "
          />
          <VehicleFlagBox
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.onCharging'
              )
            "
            icon="on-charging"
            :status="vehicle.status_flags.is_charging"
          />
          <toggle-box
            v-model="vehicle.general_flags.missing"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'missing', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="missing" />
                <div class="ml-4 title">
                  {{
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.missing'
                    )
                  }}
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.general_flags.iot_fault"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'iot_fault', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="iot-fault" />
                <div class="ml-4 title">
                  {{
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.iotFault'
                    )
                  }}
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.general_flags.geo_fence_alert"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'geo_fence_alert', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="geofence-fault" />
                <div class="ml-4 title">
                  {{
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.geoAlert'
                    )
                  }}
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.general_flags.low_battery"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'low_battery', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="low-battery" />
                <div class="ml-4 title">
                  {{
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.lowBattery'
                    )
                  }}
                </div>
              </div>
            </template>
          </toggle-box>
          <toggle-box
            v-model="vehicle.general_flags.restricted_alert"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'restricted_alert', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="restricted_alert" />
                <div class="ml-4 title">
                  {{
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.restrictedAlert'
                    )
                  }}
                </div>
              </div>
            </template>
          </toggle-box>
          <toggle-box
            v-model="vehicle.general_flags.slowzone_alert"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @click-box="makeToggleSense(false)"
            @change="onOff('G', 'slowzone_alert', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="slowzone_alert" />
                <div class="ml-4 title">
                  {{
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.flags.vehicle.flags.slowzoneAlert'
                    )
                  }}
                </div>
              </div>
            </template>
          </toggle-box>
        </div>
      </div>
    </content-section>
  </div>
</template>

<script>
import { VehicleConfig } from '@/config/VehicleConfig'

import ContentSection from '@/components/layout/ContentSection'
import OtoTypography from '@/components/ui/OtoTypography'
import ToggleBox from '@/components/form/ToggleBox.vue'
import VehicleFlagIcon from '@/components/badge/VehicleFlagIcon'
import VehicleFlagBox from '@/components/badge/VehicleFlagBox'

export default {
  name: 'ViewVehicleFlag',
  components: {
    ContentSection,
    OtoTypography,
    ToggleBox,
    VehicleFlagIcon,
    VehicleFlagBox,
  },
  data() {
    return {
      vehicle: null,
      isClicked: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getReadOnlyStatusForSleepMode() {
      if (this.$acl.canUpdate('vehicles')) {
        return false
      } else {
        return true
      }
    },
    getReadOnlyStatus() {
      if (
        this.$acl.canUpdate('vehicles') &&
        this.vehicle.task &&
        this.vehicle.task.task_status === 'PICKED'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    console.log({ cre: this.vehicle })
    this.vehicle = this.$store.getters['vehicledetail/getData']

    if (!this.vehicle) {
      console.log({ fetching: this.id })
      await this.$store.dispatch('vehicledetail/fetchData', this.id)
      this.vehicle = this.$store.getters['vehicledetail/getData']
    }
    console.log({ crEE: this.vehicle })
  },
  methods: {
    makeToggleSense(useOperationFlagStaus) {
      if (useOperationFlagStaus) {
        if (this.vehicle?.task?.task_status === 'PICKED') {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'You cannot change Flag while vehicle on task',
            },
            5000
          )
        }
      }

      this.isClicked = true
    },

    async onOff(group, key, val) {
      await setTimeout(() => {
        if (this.isClicked) {
          let url = () => {
            if (group === 'general' || group === 'G') {
              return VehicleConfig.api.updateGeneralFLags(this.id)
            }
            if (group === 'operational' || group === 'O') {
              return VehicleConfig.api.updateOperationalFLags(this.id)
            }
            if (group === 'status' || group === 'S') {
              return VehicleConfig.api.updateStatusFLags(this.id)
            }
          }
          let updateModel = (modelKey, modelVal) => {
            let aliases = {
              G: 'general_flags',
              general: 'general_flags',
              O: 'operational_flags',
              operational: 'operational_flags',
              S: 'status_flags',
              status: 'status_flags',
            }
            let type = aliases[group] || null
            this.vehicle[type][modelKey] = modelVal
            return this.vehicle
            // console.log({ upMoType: type });
            // console.log({ upMo: this.vehicle[type][modelKey] });
            // console.log({ upMoDD: this.vehicle[type] });
          }
          console.log({ end: url() })
          let data = new FormData()
          data.append(key, val)
          // console.log("ad-", key);
          // console.log("adV-", val);
          this.$http
            .patch(url(), data)
            .then((res) => {
              console.log('res-', res.data)
              this.isClicked = false
              //temporary solution--TODO
              // this.$router.go()
              if (group === 'O' || group === 'operational') {
                this.vehicle.operational_flags = res.data
                this.$store.dispatch('vehicledetail/saveData', this.Vehicle)
              } else {
                let updatedModel = updateModel(key, val)
                console.log('res-updatedModel', updatedModel)
                this.$store.dispatch('vehicledetail/saveData', updatedModel)
              }

              this.$notify(
                {
                  group: 'generic',
                  type: 'success',
                  title: 'Status updated',
                  text: 'Status updated',
                },
                5000
              )
            })
            .catch((err) => {
              console.log('onOff-e', { err })
              // let groupKey
              // if (group === 'general' || group === 'G') {
              //   groupKey = 'general_flags'
              // }
              // if (group === 'operational' || group === 'O') {
              //   groupKey = 'operational_flags'
              // }
              // if (group === 'status' || group === 'S') {
              //   groupKey = 'status_flags'
              // }

              // this.vehicle[groupKey][key] = !val

              // this.$notify(
              //   {
              //     group: 'generic',
              //     type: 'error',
              //     title: 'Error',
              //     text: `${err.response.data.message}`,
              //   },
              //   5000
              // )
            })
        }
      }, 200)
    },
  },
}
</script>

<style lang="scss"></style>
